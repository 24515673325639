import { CDK_DRAG_CONFIG } from '@angular/cdk/drag-drop';
import {
  ImageLoaderConfig,
  IMAGE_CONFIG,
  IMAGE_LOADER,
  registerLocaleData,
} from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeNL from '@angular/common/locales/nl';
import { inject, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouterModule, Routes } from '@angular/router';
import { NotifierModule } from 'angular-notifier';
import { AppComponent } from './app.component';
import { ImagesModule } from './images/images.module';
import { LoginModule } from './login/login.module';
import { ProductsModule } from './products/products.module';
import { VideosModule } from './videos/videos.module';
import {
  AuthInterceptor,
  ErrorInterceptor,
  LoadInterceptor,
} from './_shared/interceptors';
import { AuthService } from './_shared/services';
import { SharedModule } from './_shared/shared.module';

registerLocaleData(localeNL, 'nl');

const routes: Routes = [
  {
    path: 'wat-ik-maak',
    loadChildren: () => ProductsModule,
  },
  {
    path: 'wie-ik-ben',
    loadChildren: () => ImagesModule,
  },
  {
    path: 'enkele-filmpjes',
    loadChildren: () => VideosModule,
  },
  {
    path: 'inloggen',
    loadChildren: () => LoginModule,
    canActivate: [
      () => (inject(AuthService).token ? inject(Router).parseUrl('/') : true),
    ],
  },
  {
    path: '**',
    redirectTo: 'wat-ik-maak',
  },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    SharedModule.forRoot(),
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'middle',
          distance: 24,
        },
        vertical: {
          position: 'top',
          distance: 24,
        },
      },
      behaviour: {
        autoHide: 2000,
        onClick: 'hide',
        onMouseover: 'pauseAutoHide',
        showDismissButton: true,
        stacking: 4,
      },
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadInterceptor,
      multi: true,
    },
    {
      provide: CDK_DRAG_CONFIG,
      useValue: { zIndex: 10000, listOrientation: 'horizontal' },
    },
    {
      provide: LOCALE_ID,
      useValue: 'nl',
    },
    {
      provide: IMAGE_LOADER,
      useValue: (config: ImageLoaderConfig) =>
        `${config.src}${config.width ? `?width=${config.width}` : ''}`,
    },
    {
      provide: IMAGE_CONFIG,
      useValue: {
        breakpoints: [200, 300, 500, 700, 1000],
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
