<div class="modal-header">
  <h4 class="modal-title">Zeker?</h4>
  <button type="button" class="btn btn-close" (click)="close()"></button>
</div>
<div class="modal-body">
  Weet je zeker dat je {{ name || 'dit' }} wilt verwijderen?
</div>
<div class="modal-footer d-flex justify-content-between">
  <button type="button" class="btn btn-secondary" (click)="close()">
    <fa-icon [icon]="fa.times" class="me-1"></fa-icon>
    nee
  </button>

  <button type="button" class="btn btn-danger" (click)="delete()">
    <fa-icon [icon]="fa.delete" class="me-1"></fa-icon>
    ja
  </button>
</div>
