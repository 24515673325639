<nav class="navbar navbar-expand-md bg-material navbar-dark py-md-3">
  <div class="container align-items-stretch">
    <div class="d-flex flex-column justify-content-between text-white me-n6">
      <a [routerLink]="['/']" class="navbar-brand">
        <div class="alex-name">alex den braver</div>
        <div class="alex-title">ontwerper en bouwer van creatieve gebouwen</div>
      </a>

      <div
        class="navbar-nav navbar-openable d-md-flex ms-md-n3 mb-n2 mt-3 mt-md-0"
        [class.navbar-open]="navbarOpen"
      >
        <a
          class="nav-link"
          [routerLink]="['/wat-ik-maak']"
          (click)="navbarOpen = false"
          routerLinkActive="active"
        >
          wat ik maak
        </a>

        <a
          class="nav-link"
          [routerLink]="['/wie-ik-ben']"
          (click)="navbarOpen = false"
          routerLinkActive="active"
        >
          wie ik ben
        </a>

        <a
          class="nav-link"
          [routerLink]="['/enkele-filmpjes']"
          (click)="navbarOpen = false"
          routerLinkActive="active"
        >
          enkele filmpjes
        </a>
      </div>
    </div>

    <div
      class="d-flex flex-column align-items-end justify-content-between ms-n6"
    >
      <button
        class="navbar-toggler pe-0"
        type="button"
        (click)="navbarOpen = !navbarOpen"
        aria-label="navbar-toggle"
        [style.min-height.px]="51"
      >
        <fa-icon [icon]="navbarOpen ? fa.times : fa.bars" size="lg"></fa-icon>
      </button>

      <div
        class="h-100 navbar-openable flex-column justify-content-between d-md-block pt-2 pt-md-0 text-white text-end mt-3 mt-md-0"
        [class.navbar-open]="navbarOpen"
      >
        <div class="text-nowrap">Peizerweg 298</div>
        <div class="text-nowrap">9744 BG Groningen</div>
        <div class="text-nowrap">
          <a class="unlink" href="tel:+{{ '316' }}{{ '3074' }}{{ '6681' }}">
            06 {{ '3074' }}{{ '6681' }}
          </a>
        </div>
        <div class="text-nowrap">
          <a
            class="unlink"
            href="mailto:{{ 'alexdenbraver' }}@{{ 'planet' }}.{{ 'nl' }}"
          >
            {{ 'alexdenbraver' }}@{{ 'planet' }}.{{ 'nl' }}
          </a>
        </div>
      </div>
    </div>
  </div>
</nav>

<div class="pt-4 pb-5">
  <router-outlet></router-outlet>
  <notifier-container></notifier-container>
</div>
