import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ImageApi } from '@app/_shared/apis';
import { Image } from '@app/_shared/entities';
import { AuthService, UtilityService } from '@app/_shared/services';
import {
  faPencil,
  faSortAmountAsc,
  faTrash,
  faUpload,
} from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { firstValueFrom } from 'rxjs';
import { ImageModalComponent } from '../image-modal/image-modal.component';
import { ImagesModalComponent } from '../images-modal/images-modal.component';
import { OrderModalComponent } from '../order-modal/order-modal.component';

@Component({
  templateUrl: './images.component.html',
})
export class ImagesComponent {
  readonly fa = {
    edit: faPencil,
    delete: faTrash,
    upload: faUpload,
    order: faSortAmountAsc,
  };

  images: Image[];

  get token(): string | undefined {
    return this.authService.token;
  }

  constructor(
    readonly route: ActivatedRoute,
    private readonly modalService: NgbModal,
    private readonly authService: AuthService,
    private readonly imageApi: ImageApi,
    private readonly notifier: NotifierService,
    private readonly utility: UtilityService,
    readonly title: Title,
  ) {
    title.setTitle('Alex den Braver - Wie ik ben');

    this.images = route.snapshot.data['images'];
  }

  async upload(): Promise<void> {
    const modal = this.modalService.open(ImagesModalComponent);

    try {
      this.images?.push(...(await modal.result));
    } catch {}
  }

  async order(): Promise<void> {
    const modal = this.modalService.open(OrderModalComponent, { size: 'xl' });
    const instance: OrderModalComponent = modal.componentInstance;

    instance.images = [...this.images];

    try {
      this.images = await modal.result;
    } catch {}
  }

  async edit(image: Image): Promise<void> {
    const modal = this.modalService.open(ImageModalComponent);
    const instance: ImageModalComponent = modal.componentInstance;

    instance.image = image;

    try {
      Object.assign(image, await modal.result);
    } catch {}
  }

  async delete(image: Image): Promise<void> {
    if (!(await this.utility.delete('deze foto'))) {
      return;
    }

    await firstValueFrom(this.imageApi.delete(image.id));

    this.notifier.notify('success', 'Foto is verwijderd.');

    this.images = this.images!.filter(i => i.id !== image.id);
  }
}
