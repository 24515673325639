import { Component } from '@angular/core';
import { faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './delete-modal.component.html',
})
export class DeleteModalComponent {
  readonly fa = {
    times: faTimes,
    delete: faTrash,
  };

  name?: string;

  constructor(private readonly modal: NgbActiveModal) {}

  delete(): void {
    this.modal.close();
  }

  close(): void {
    this.modal.dismiss();
  }
}
