import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Video } from '../entities';
import { toClass, toClasses } from '../operators';

@Injectable()
export class VideoApi {
  constructor(private readonly http: HttpClient) {}

  findAll(): Observable<Video[]> {
    return this.http.get<Video[]>('/api/videos').pipe(toClasses(Video));
  }

  findOneById(id: number): Observable<Video> {
    return this.http.get<Video>(`/api/video/${id}`).pipe(toClass(Video));
  }

  save(video: Partial<Video>): Observable<Video> {
    return this.http.post<Video>('/api/video', video).pipe(toClass(Video));
  }

  order(videos: Partial<Video>[]): Observable<Video[]> {
    return this.http
      .post<Video[]>('/api/videos/order', videos)
      .pipe(toClasses(Video));
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(`/api/video/${id}`);
  }
}
