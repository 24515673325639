<div class="container">
  <div class="row">
    <div class="offset-lg-4 col-lg-4 offset-md-2 col-md-8">
      <h3 class="mb-3">Administratie</h3>

      <form [formGroup]="form" (submit)="login()">
        <div class="form-group">
          <label for="mail" class="required">Mailadres</label>
          <input
            type="email"
            class="form-control"
            id="mail"
            formControlName="mail"
            #mail="formControl"
            name="mail"
          />
          <span *ngIf="mail.errors?.['required']" class="invalid-feedback">
            Dit veld is verplicht.
          </span>
          <span *ngIf="mail.errors?.['email']" class="invalid-feedback">
            Dit veld moet een mailadres zijn.
          </span>
        </div>

        <div class="form-group">
          <label for="password" class="required">Wachtwoord</label>
          <input
            type="password"
            class="form-control"
            id="password"
            formControlName="password"
            #password="formControl"
            name="password"
          />
          <span *ngIf="password.errors?.['required']" class="invalid-feedback">
            Dit veld is verplicht.
          </span>
          <span *ngIf="password.errors?.['minlength']" class="invalid-feedback">
            Dit veld moet minstens 8 karakters lang zijn.
          </span>
        </div>

        <div class="mt-4 d-flex justify-content-end">
          <button
            type="submit"
            class="btn btn-primary"
            [disabled]="form.invalid"
          >
            Inloggen
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
