import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { ProductApi } from '@app/_shared/apis';
import { Product } from '@app/_shared/entities';
import { catchError, EMPTY, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductResolver implements Resolve<Product> {
  constructor(
    private readonly productApi: ProductApi,
    private readonly router: Router,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Product> {
    return this.productApi.findOneBySlug(route.params['slug']).pipe(
      catchError(() => {
        this.router.navigate(['/']);

        return EMPTY;
      }),
    );
  }
}
