import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@app/_shared/services';
import { Observable, tap } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private readonly authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (this.authService.token) {
      request = request.clone({
        setHeaders: { Authorization: this.authService.token },
      });
    }

    return next.handle(request).pipe(
      tap(() => {
        this.authService.refresh();
      }),
    );
  }
}
