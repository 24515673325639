import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ProductApi } from '@app/_shared/apis';
import { Product } from '@app/_shared/entities';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductsResolver implements Resolve<Product[]> {
  constructor(private readonly productApi: ProductApi) {}

  resolve(): Observable<Product[]> {
    return this.productApi.findAll();
  }
}
