import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ImageApi } from '@app/_shared/apis';
import { Image } from '@app/_shared/entities';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ImagesResolver implements Resolve<Image[]> {
  constructor(private readonly imageApi: ImageApi) {}

  resolve(): Observable<Image[]> {
    return this.imageApi.findAll();
  }
}
