import { Injectable } from '@angular/core';

const KEY = 'token';
const SECONDS = 59 * 60;

@Injectable()
export class AuthService {
  token?: string;

  private timer?: ReturnType<typeof setTimeout>;

  constructor() {
    const token = localStorage.getItem(KEY);

    if (token) {
      this.login(token);
    }
  }

  login(token: string): void {
    this.token = token;

    localStorage.setItem(KEY, token);
  }

  refresh(): void {
    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => {
      this.logout();
    }, SECONDS * 1000);
  }

  logout(): void {
    this.token = undefined;

    if (this.timer) {
      clearTimeout(this.timer);
    }

    localStorage.removeItem(KEY);
  }
}
