import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '@app/_shared/shared.module';
import { OrderModalComponent } from './order-modal/order-modal.component';
import { VideoModalComponent } from './video-modal/video-modal.component';
import { VideosComponent } from './videos/videos.component';
import { VideosResolver } from './videos/videos.resolver';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: VideosComponent,
    resolve: {
      videos: VideosResolver,
    },
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  declarations: [VideosComponent, VideoModalComponent, OrderModalComponent],
  imports: [SharedModule, RouterModule.forChild(routes)],
})
export class VideosModule {}
