<nav
  *ngIf="loggedin"
  class="navbar navbar-dark overflow-hidden bg-success mt-n4 mb-4"
>
  <div class="container">
    <div class="w-100 navbar-nav flex-row justify-content-between">
      <div class="admin-bar">
        <ng-container *ngTemplateOutlet="buttons || null"></ng-container>
      </div>
      <div class="admin-bar">
        <a role="button" (click)="users()">
          <fa-icon [icon]="fa.users"></fa-icon>
          <span>gebruikers</span>
        </a>

        <a role="button" (click)="logout()">
          <fa-icon [icon]="fa.signout"></fa-icon>
          <span>uitloggen</span>
        </a>
      </div>
    </div>
  </div>
</nav>
