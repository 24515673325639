import { Component } from '@angular/core';
import {
  DomSanitizer,
  SafeResourceUrl,
  Title,
} from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { VideoApi } from '@app/_shared/apis';
import { Video } from '@app/_shared/entities';
import { AuthService, UtilityService } from '@app/_shared/services';
import {
  faPencil,
  faPlus,
  faSortAmountAsc,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { firstValueFrom } from 'rxjs';
import { OrderModalComponent } from '../order-modal/order-modal.component';
import { VideoModalComponent } from '../video-modal/video-modal.component';

@Component({
  templateUrl: './videos.component.html',
})
export class VideosComponent {
  readonly fa = {
    plus: faPlus,
    edit: faPencil,
    delete: faTrash,
    order: faSortAmountAsc,
  };

  videos: Video[];
  urls: SafeResourceUrl[] = [];

  get token(): string | undefined {
    return this.authService.token;
  }

  constructor(
    readonly route: ActivatedRoute,
    private readonly modalService: NgbModal,
    private readonly authService: AuthService,
    private readonly videoApi: VideoApi,
    private readonly notifier: NotifierService,
    private readonly sanitizer: DomSanitizer,
    private readonly utility: UtilityService,
    readonly title: Title,
  ) {
    this.videos = route.snapshot.data['videos'];

    title.setTitle('Alex den Braver - Enkele filmpjes');

    this.refreshUrls();
  }

  async add(): Promise<void> {
    const modal = this.modalService.open(VideoModalComponent);

    try {
      this.videos.push(await modal.result);
      this.refreshUrls();
    } catch {}
  }

  async order(): Promise<void> {
    const modal = this.modalService.open(OrderModalComponent, { size: 'xl' });
    const instance: OrderModalComponent = modal.componentInstance;

    instance.videos = [...this.videos];

    try {
      this.videos = await modal.result;
      this.refreshUrls();
    } catch {}
  }

  async edit(video: Video): Promise<void> {
    const modal = this.modalService.open(VideoModalComponent);
    const instance: VideoModalComponent = modal.componentInstance;

    instance.video = video;

    try {
      Object.assign(video, await modal.result);
    } catch {}
  }

  async delete(video: Video): Promise<void> {
    if (!(await this.utility.delete('deze video'))) {
      return;
    }

    await firstValueFrom(this.videoApi.delete(video.id));

    this.notifier.notify('success', 'Video is verwijderd.');

    this.videos = this.videos!.filter(i => i.id !== video.id);
    this.refreshUrls();
  }

  refreshUrls(): void {
    this.urls = this.videos.map(v =>
      this.sanitizer.bypassSecurityTrustResourceUrl(v.video),
    );
  }
}
