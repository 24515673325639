<app-admin-bar>
  <ng-template>
    <a role="button" (click)="add()">
      <fa-icon [icon]="fa.plus"></fa-icon>
      <span>toevoegen</span>
    </a>
    <a *ngIf="videos.length > 1" role="button" (click)="order()">
      <fa-icon [icon]="fa.order"></fa-icon>
      <span>volgorde</span>
    </a>
  </ng-template>
</app-admin-bar>

<div class="container">
  <h3 class="d-flex justify-content-between mb-4">enkele filmpjes</h3>

  <div
    *ngFor="let video of videos; let i = index"
    class="h-100 d-flex flex-column justify-content-end align-items-center mb-4"
  >
    <div class="w-100 position-relative">
      <div class="video-container">
        <iframe
          width="560"
          height="349"
          [src]="urls[i]"
          frameborder="0"
          allowfullscreen="allowfullscreen"
          scrolling="no"
        ></iframe>
      </div>

      <div *ngIf="token" class="position-absolute bottom-0 end-0">
        <button
          type="button"
          class="btn btn-success me-3 mb-3"
          (click)="edit(video)"
        >
          <fa-icon [icon]="fa.edit" class="me-1"></fa-icon>
          bewerken
        </button>

        <button
          type="button"
          class="btn btn-danger me-3 mb-3"
          (click)="delete(video)"
        >
          <fa-icon [icon]="fa.delete" class="me-1"></fa-icon>
          verwijderen
        </button>
      </div>
    </div>

    <div
      *ngIf="video.description"
      class="text-center mt-2"
      [innerHTML]="video.description"
    ></div>
  </div>
</div>
