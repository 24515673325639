import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Product, ProductImage } from '../entities';
import { toClass, toClasses } from '../operators';

@Injectable()
export class ProductApi {
  constructor(private readonly http: HttpClient) {}

  findAll(): Observable<Product[]> {
    return this.http.get<Product[]>('/api/products').pipe(toClasses(Product));
  }

  findOneBySlug(slug: string): Observable<Product> {
    return this.http
      .get<Product>(`/api/product/${slug}`)
      .pipe(toClass(Product));
  }

  save(product: Partial<Product>): Observable<Product> {
    return this.http
      .post<Product>('/api/product', product)
      .pipe(toClass(Product));
  }

  uploadImage(slug: string, file: File): Observable<ProductImage> {
    const formData = new FormData();
    formData.append('file', file);

    return this.http
      .post<ProductImage>(`/api/product/${slug}/upload`, formData)
      .pipe(toClass(ProductImage));
  }

  orderImages(product: Partial<Product>): Observable<Product> {
    return this.http
      .post<Product>('/api/product/order-images', product)
      .pipe(toClass(Product));
  }

  delete(slug: string): Observable<void> {
    return this.http.delete<void>(`/api/product/${slug}`);
  }
}
