import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalComponent } from '../components';

@Injectable()
export class UtilityService {
  constructor(private readonly modalService: NgbModal) {}

  async delete(name?: string): Promise<boolean> {
    const modal = this.modalService.open(DeleteModalComponent, { size: 'sm' });
    const instance: DeleteModalComponent = modal.componentInstance;

    instance.name = name;

    try {
      await modal.result;

      return true;
    } catch {
      return false;
    }
  }
}
