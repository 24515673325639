import { Directive } from '@angular/core';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Directive({
  selector: 'ckeditor',
})
export class CkeditorDirective {
  constructor(readonly cdkeditor: CKEditorComponent) {
    cdkeditor.editor = ClassicEditor;
    cdkeditor.config = {
      toolbar: ['bold', 'italic', '|', 'link', '|', 'undo', 'redo'],
      link: { addTargetToExternalLinks: true },
    };
  }
}
