<form [formGroup]="$any(form)" (submit)="save()">
  <div class="modal-header">
    <h4 class="modal-title">Gebruikers bewerken</h4>
    <button type="button" class="btn btn-close" (click)="close()"></button>
  </div>
  <div
    *ngFor="let control of form.controls; let i = index"
    class="modal-body border-bottom"
  >
    <div [formGroupName]="i" class="form-group">
      <label for="mail" class="required">Mailadres</label>
      <input
        type="email"
        id="mail"
        name="mail"
        formControlName="mail"
        #mail="formControl"
        class="form-control"
      />
      <span *ngIf="mail.errors?.['required']" class="invalid-feedback">
        Dit veld is verplicht.
      </span>
      <span *ngIf="mail.errors?.['email']" class="invalid-feedback">
        Dit veld moet een mailadres zijn.
      </span>
      <span *ngIf="mail.errors?.['maxlength']" class="invalid-feedback">
        Dit veld mag maximaal 200 karakters lang zijn.
      </span>
    </div>

    <div [formGroupName]="i" class="form-group">
      <label for="password" [class.required]="!control.value.id">
        Wachtwoord

        <span *ngIf="control.value.id" class="text-muted">
          (leeglaten om hetzelfde te laten)
        </span>
      </label>
      <input
        type="text"
        id="password"
        name="password"
        formControlName="password"
        #password="formControl"
        class="form-control"
      />
      <span *ngIf="password.errors?.['required']" class="invalid-feedback">
        Dit veld is verplicht.
      </span>
      <span *ngIf="password.errors?.['minlength']" class="invalid-feedback">
        Dit veld moet minstens 8 karakters lang zijn.
      </span>
    </div>

    <div class="d-flex justify-content-end">
      <button
        type="button"
        class="d-flex btn btn-secondary"
        (click)="deblock(control.value.id)"
        [disabled]="!control.value.id"
      >
        <fa-icon [icon]="fa.deblock" class="me-2"></fa-icon>
        <div>deblokkeren</div>
      </button>

      <button
        type="button"
        class="d-flex btn btn-danger ms-3"
        (click)="delete(i)"
      >
        <fa-icon [icon]="fa.delete" class="me-2"></fa-icon>
        <div>verwijderen</div>
      </button>
    </div>
  </div>

  <div class="modal-body">
    <div class="d-flex justify-content-end">
      <button type="button" class="d-flex btn btn-primary" (click)="add()">
        <fa-icon [icon]="fa.add" class="me-2"></fa-icon>
        <div>toevoegen</div>
      </button>
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-end">
    <button
      type="submit"
      class="btn btn-success"
      [disabled]="form.invalid || !form.dirty"
    >
      <fa-icon [icon]="fa.save" class="me-1"></fa-icon>
      opslaan
    </button>
  </div>
</form>
