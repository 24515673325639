import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { VideoApi } from '@app/_shared/apis';
import { Video } from '@app/_shared/entities';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VideosResolver implements Resolve<Video[]> {
  constructor(private readonly videoApi: VideoApi) {}

  resolve(): Observable<Video[]> {
    return this.videoApi.findAll();
  }
}
