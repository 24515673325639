import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component } from '@angular/core';
import { ProductApi } from '@app/_shared/apis';
import { Product, ProductImage } from '@app/_shared/entities';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { firstValueFrom } from 'rxjs';

@Component({
  templateUrl: './order-modal.component.html',
})
export class OrderModalComponent {
  readonly fa = {
    save: faPencil,
  };

  product?: Product;
  images: ProductImage[] = [];

  constructor(
    private readonly modal: NgbActiveModal,
    private readonly notifier: NotifierService,
    private readonly productApi: ProductApi,
  ) {}

  async save(): Promise<void> {
    if (this.product) {
      try {
        const product = await firstValueFrom(
          this.productApi.orderImages({
            ...this.product,
            _productImages: this.images,
          }),
        );

        this.notifier.notify('success', 'De volgorde is opgeslagen.');

        this.modal.close(product);
      } catch {}
    }
  }

  drop(event: CdkDragDrop<ProductImage[]>): void {
    if (this.product) {
      moveItemInArray(this.images, event.previousIndex, event.currentIndex);
    }
  }

  close(): void {
    this.modal.dismiss();
  }
}
