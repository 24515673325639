import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Product, ProductType } from '@app/_shared/entities';
import { faPlus, faTags } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductModalComponent } from '../product-modal/product-modal.component';
import { TypesModalComponent } from '../types-modal/types-modal.component';

@Component({
  templateUrl: './products.component.html',
})
export class ProductsComponent {
  readonly fa = {
    plus: faPlus,
    types: faTags,
  };

  products: Product[];
  types: ProductType[];

  type?: ProductType;

  constructor(
    readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly modalService: NgbModal,
    readonly title: Title,
  ) {
    this.products = route.snapshot.data['products'];
    this.types = route.snapshot.data['types'];

    title.setTitle('Alex den Braver - Wat ik maak');
  }

  async add(): Promise<void> {
    const modal = this.modalService.open(ProductModalComponent);

    try {
      const product: Product | undefined = await modal.result;

      if (product) {
        this.router.navigate(['/wat-ik-maak', product.slug]);
      }
    } catch {}
  }

  async editTypes(): Promise<void> {
    const modal = this.modalService.open(TypesModalComponent);
    const instance: TypesModalComponent = modal.componentInstance;

    instance.types = this.types;

    try {
      this.types = await modal.result;
    } catch {}
  }
}
