import { AbstractControl, ValidatorFn } from '@angular/forms';

export function uniqueValidator<T = any>(
  values: T[],
  mapper: (value: T) => T = t => t,
): ValidatorFn {
  return (control: AbstractControl<T>) => {
    if (values.includes(mapper(control.value))) {
      return { unique: 'already exists' };
    } else {
      return null;
    }
  };
}
