import { trim } from '../utility';

export class Video {
  id: number;
  source: string;
  description: string;
  order: number;

  get youtube(): boolean {
    return this.source.includes('youtube');
  }

  get video(): string {
    if (this.youtube) {
      const id = this.source.split('?v=')[1];

      return `https://www.youtube.com/embed/${id}?rel=0&hd=1`;
    } else {
      const id = this.source.split('vimeo.com/')[1]?.split('?')[0];

      return `https://player.vimeo.com/video/${id}`;
    }
  }

  get image(): string {
    if (this.youtube) {
      const id = this.source.split('?v=')[1];

      return `https://img.youtube.com/vi/${id}/0.jpg`;
    } else {
      return 'https://img.youtube.com';
    }
  }

  constructor(video: Video) {
    this.id = video.id;
    this.source = video.source;
    this.description = trim(video.description);
    this.order = video.order;
  }
}
