import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component } from '@angular/core';
import { VideoApi } from '@app/_shared/apis';
import { Video } from '@app/_shared/entities';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { firstValueFrom } from 'rxjs';

@Component({
  templateUrl: './order-modal.component.html',
})
export class OrderModalComponent {
  readonly fa = {
    save: faPencil,
  };

  videos: Video[] = [];

  constructor(
    private readonly modal: NgbActiveModal,
    private readonly notifier: NotifierService,
    private readonly videoApi: VideoApi,
  ) {}

  async save(): Promise<void> {
    try {
      const videos = await firstValueFrom(this.videoApi.order(this.videos));

      this.notifier.notify('success', 'De volgorde is opgeslagen.');

      this.modal.close(videos);
    } catch {}
  }

  drop(event: CdkDragDrop<Video[]>): void {
    moveItemInArray(this.videos, event.previousIndex, event.currentIndex);
  }

  close(): void {
    this.modal.dismiss();
  }
}
