import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProductTypeApi } from '@app/_shared/apis';
import { ProductType } from '@app/_shared/entities';
import { UtilityService } from '@app/_shared/services';
import { faPencil, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { firstValueFrom } from 'rxjs';

@Component({
  templateUrl: './types-modal.component.html',
})
export class TypesModalComponent implements OnInit {
  readonly fa = {
    add: faPlus,
    delete: faTrash,
    save: faPencil,
  };

  readonly form = new FormArray<
    FormGroup<{
      id: FormControl<number | undefined>;
      name: FormControl<string>;
    }>
  >([]);

  types: ProductType[] = [];

  constructor(
    private readonly modal: NgbActiveModal,
    private readonly notifier: NotifierService,
    private readonly typeApi: ProductTypeApi,
    private readonly utility: UtilityService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.types.forEach(() => this.add());

    this.form.patchValue(this.types);

    this.form.markAsPristine();
  }

  add(): void {
    this.form.push(
      new FormGroup({
        id: new FormControl<number | undefined>(undefined, {
          nonNullable: true,
        }),
        name: new FormControl<string>('', {
          nonNullable: true,
          validators: [Validators.required, Validators.maxLength(200)],
        }),
      }),
    );
  }

  async delete(index: number): Promise<void> {
    if (
      !(await this.utility.delete(this.form.controls.at(index)?.value.name))
    ) {
      return;
    }

    this.form.removeAt(index);

    this.form.markAsDirty({ onlySelf: true });
  }

  async save(): Promise<void> {
    const ids = this.form.value.map(t => t.id);

    for (const type of this.types) {
      if (!ids.includes(type.id)) {
        await firstValueFrom(this.typeApi.delete(type.id));
      }
    }

    for (const control of this.form.controls) {
      if (control.dirty) {
        await firstValueFrom(this.typeApi.save(control.value));
      }
    }

    this.notifier.notify('success', 'De categorieën zijn opgeslagen.');

    this.modal.close(await firstValueFrom(this.typeApi.findAll()));
  }

  close(): void {
    this.modal.dismiss();
  }
}
