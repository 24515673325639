export class User {
  id: number;
  mail: string;
  password?: string;

  constructor(user: User) {
    this.id = user.id;
    this.mail = user.mail;
    this.password = user.password;
  }
}
