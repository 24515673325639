import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserApi } from '@app/_shared/apis';
import { User } from '@app/_shared/entities';
import { UtilityService } from '@app/_shared/services';
import {
  faPencil,
  faPlus,
  faTrash,
  faUnlock,
} from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { firstValueFrom } from 'rxjs';

@Component({
  templateUrl: './users-modal.component.html',
})
export class UsersModalComponent implements OnInit {
  readonly fa = {
    add: faPlus,
    delete: faTrash,
    deblock: faUnlock,
    save: faPencil,
  };

  readonly form = new FormArray<
    FormGroup<{
      id: FormControl<number | undefined>;
      mail: FormControl<string>;
      password: FormControl<string | undefined>;
    }>
  >([]);

  users: User[] = [];

  constructor(
    private readonly modal: NgbActiveModal,
    private readonly notifier: NotifierService,
    private readonly userApi: UserApi,
    private readonly utility: UtilityService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.users = await firstValueFrom(this.userApi.findAll());

    this.users.forEach(() => this.add(true));

    this.form.patchValue(this.users);

    this.form.markAsPristine();
  }

  add(existing = false): void {
    this.form.push(
      new FormGroup({
        id: new FormControl<number | undefined>(undefined, {
          nonNullable: true,
        }),
        mail: new FormControl<string>('', {
          nonNullable: true,
          validators: [
            Validators.required,
            Validators.email,
            Validators.maxLength(200),
          ],
        }),
        password: new FormControl<string | undefined>(undefined, {
          nonNullable: true,
          validators: existing
            ? [Validators.minLength(8)]
            : [Validators.required, Validators.minLength(8)],
        }),
      }),
    );
  }

  async deblock(id: number | undefined): Promise<void> {
    if (id) {
      await firstValueFrom(this.userApi.deblock(id));

      this.notifier.notify('success', 'De gebruiker is gedeblokkeerd.');
    }
  }

  async delete(index: number): Promise<void> {
    if (!(await this.utility.delete('deze gebruiker'))) {
      return;
    }

    this.form.removeAt(index);

    this.form.markAsDirty({ onlySelf: true });
  }

  async save(): Promise<void> {
    const ids = this.form.value.map(t => t.id);

    for (const user of this.users) {
      if (!ids.includes(user.id)) {
        await firstValueFrom(this.userApi.delete(user.id));
      }
    }

    for (const control of this.form.controls) {
      if (control.dirty) {
        await firstValueFrom(this.userApi.save(control.value));
      }
    }

    this.notifier.notify('success', 'De gebruikers zijn opgeslagen.');

    this.modal.close(await firstValueFrom(this.userApi.findAll()));
  }

  close(): void {
    this.modal.dismiss();
  }
}
