import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProductApi, ProductTypeApi } from '@app/_shared/apis';
import { Product, ProductType } from '@app/_shared/entities';
import { uniqueValidator } from '@app/_shared/validators';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { firstValueFrom } from 'rxjs';

@Component({
  templateUrl: './product-modal.component.html',
})
export class ProductModalComponent implements OnInit {
  readonly fa = {
    save: faPencil,
  };

  readonly form = new FormGroup({
    slug: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
    }),
    name: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, Validators.maxLength(200)],
    }),
    _productType: new FormControl<ProductType | undefined>(undefined, {
      nonNullable: true,
    }),
    date: new FormControl<Date>(undefined as unknown as Date, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    description: new FormControl<string>('', {
      nonNullable: true,
    }),
  });

  readonly types$ = this.typeApi.findAll();

  product?: Product;

  constructor(
    private readonly modal: NgbActiveModal,
    private readonly notifier: NotifierService,
    private readonly productApi: ProductApi,
    private readonly typeApi: ProductTypeApi,
  ) {
    this.productApi.findAll().subscribe(products => {
      this.form.controls.name.addValidators(
        uniqueValidator(
          products.filter(p => p.id !== this.product?.id).map(p => p.slug),
          v =>
            v
              .replace(/[^A-Za-z0-9-]+/g, '-')
              .split('-')
              .filter(Boolean)
              .join('-')
              .toLowerCase(),
        ),
      );
    });
  }

  async ngOnInit(): Promise<void> {
    if (this.product) {
      this.form.patchValue(this.product);
    }
  }

  async save(): Promise<void> {
    if (this.form.invalid) {
      return;
    }

    try {
      const raw: Partial<Product> = this.form.value;

      const product = await firstValueFrom(this.productApi.save(raw));

      this.notifier.notify('success', `${product.name} is opgeslagen.`);

      this.modal.close(product);
    } catch {}
  }

  close(): void {
    this.modal.dismiss();
  }
}
