import { trim } from '../utility';
import { Product } from './product';

export class ProductImage {
  id: number;
  description?: string;
  width: number;
  height: number;
  order?: number;

  _product?: Product;

  get url(): string {
    return `/api/product-image/${this.id}/download`;
  }

  get name(): string {
    return this.description?.replace(/(<([^>]+)>)/gi, '') ?? '';
  }

  constructor(productImage: ProductImage) {
    this.id = productImage.id;
    this.description = trim(productImage.description || '');
    this.width = productImage.width;
    this.height = productImage.height;
    this.order = productImage.order;

    this._product = productImage._product
      ? new Product(productImage._product)
      : undefined;
  }
}
