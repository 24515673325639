<div class="modal-header">
  <h4 class="modal-title">Foto's uploaden</h4>
  <button type="button" class="btn btn-close" (click)="close()"></button>
</div>
<div class="modal-body">
  <div
    class="file-drop"
    [class.active]="dragging"
    (click)="fileInput.click()"
    (dragover)="$event.preventDefault(); $event.stopPropagation()"
    (dragenter)="dragging = true"
    (dragleave)="dragging = false"
    (drop)="$event.preventDefault(); setImages($any($event.dataTransfer).files)"
  >
    <input
      hidden
      type="file"
      #fileInput
      (change)="setImages($any($event.target).files)"
      accept="image/*"
      multiple
    />

    <span>Drop de foto's hier of selecteer ze op je computer.</span>
  </div>

  <div class="row">
    <div *ngFor="let uri of uris" class="col-2">
      <div class="h-100 d-flex flex-column justify-content-center">
        <img [src]="uri" class="w-100 mt-3" />
      </div>
    </div>
  </div>
</div>
<div class="modal-footer d-flex justify-content-end">
  <button type="button" class="btn btn-success" (click)="upload()">
    <fa-icon [icon]="fa.upload" class="me-1"></fa-icon>

    uploaden
  </button>
</div>
