import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '@app/_shared/shared.module';
import { ImageModalComponent } from './image-modal/image-modal.component';
import { ImagesModalComponent } from './images-modal/images-modal.component';
import { ImagesComponent } from './images/images.component';
import { ImagesResolver } from './images/images.resolver';
import { OrderModalComponent } from './order-modal/order-modal.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: ImagesComponent,
    resolve: {
      images: ImagesResolver,
    },
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  declarations: [
    ImagesComponent,
    ImagesModalComponent,
    ImageModalComponent,
    OrderModalComponent,
  ],
  imports: [SharedModule, RouterModule.forChild(routes)],
})
export class ImagesModule {}
