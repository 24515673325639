import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '@app/_shared/shared.module';
import { ImageModalComponent } from './image-modal/image-modal.component';
import { ImagesModalComponent } from './images-modal/images-modal.component';
import { OrderModalComponent } from './order-modal/order-modal.component';
import { ProductModalComponent } from './product-modal/product-modal.component';
import { ProductComponent } from './product/product.component';
import { ProductResolver } from './product/product.resolver';
import { ProductsComponent } from './products/products.component';
import { ProductsResolver } from './products/products.resolver';
import { TypesResolver } from './products/types.resolver';
import { TypesModalComponent } from './types-modal/types-modal.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: ProductsComponent,
    resolve: {
      products: ProductsResolver,
      types: TypesResolver,
    },
  },
  {
    path: ':slug',
    component: ProductComponent,
    resolve: {
      product: ProductResolver,
    },
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  declarations: [
    ProductsComponent,
    ProductComponent,
    ProductModalComponent,
    TypesModalComponent,
    ImagesModalComponent,
    OrderModalComponent,
    ImageModalComponent,
  ],
  imports: [SharedModule, RouterModule.forChild(routes)],
})
export class ProductsModule {}
