import {
  Directive,
  ElementRef,
  forwardRef,
  HostListener,
  Renderer2,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop } from 'rxjs';

@Directive({
  selector:
    'input[type=date][ngModel], input[type=date][formControl], input[type=date][formControlName]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateInputDirective),
      multi: true,
    },
  ],
})
export class DateInputDirective implements ControlValueAccessor {
  @HostListener('blur', []) onTouched: () => void = noop;
  @HostListener('input', ['$event']) onChange: (value: any) => void = noop;

  private valueType: 'value' | 'valueAsDate' = 'value';

  constructor(
    private readonly renderer: Renderer2,
    private readonly elementRef: ElementRef,
  ) {}

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = (event: any) => fn(event.target[this.valueType]);
  }

  writeValue(value: Date | string): void {
    this.valueType = typeof value === 'string' ? 'value' : 'valueAsDate';

    this.renderer.setProperty(
      this.elementRef.nativeElement,
      this.valueType,
      value,
    );
  }
}
