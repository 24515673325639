import { Product } from './product';

export class ProductType {
  id: number;
  name: string;

  products?: number;
  _products?: Product[];

  constructor(productType: ProductType) {
    this.id = productType.id;
    this.name = productType.name;
    this.products = productType.products;

    this._products = productType._products?.map(p => new Product(p));
  }
}
