import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { sleep } from '../utility';

@Directive({
  selector: '[appAlignImages]',
})
export class AlignImagesDirective {
  constructor(
    private readonly renderer: Renderer2,
    private readonly elementRef: ElementRef<HTMLDivElement>,
  ) {
    this.align();
  }

  @HostListener('window:resize')
  @HostListener('window:zoom')
  async align(): Promise<void> {
    await sleep(0);

    const cols = Array.from(this.elementRef.nativeElement.children);

    if (cols.length) {
      const n = Number(getComputedStyle(cols[0]).getPropertyValue('--cols'));

      if (n > 1) {
        for (let i = 0; i < cols.length; i += n) {
          const divs = cols
            .slice(i, i + n)
            .map(el => el.children[0].children[1]);

          const maxHeight = Math.max(
            ...divs.filter(el => el).map(el => el.clientHeight),
          );

          for (const div of divs) {
            if (div) {
              this.renderer.setStyle(
                div,
                'margin-bottom',
                `${maxHeight - div.clientHeight}px`,
              );
            }
          }
        }
      }
    }
  }
}
