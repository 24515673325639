import { Component, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductApi, ProductImageApi } from '@app/_shared/apis';
import { AlignImagesDirective } from '@app/_shared/directives';
import { Product, ProductImage } from '@app/_shared/entities';
import { AuthService, UtilityService } from '@app/_shared/services';
import {
  faPencil,
  faSortAmountAsc,
  faTrash,
  faUpload,
} from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { firstValueFrom } from 'rxjs';
import { ImageModalComponent } from '../image-modal/image-modal.component';
import { ImagesModalComponent } from '../images-modal/images-modal.component';
import { OrderModalComponent } from '../order-modal/order-modal.component';
import { ProductModalComponent } from '../product-modal/product-modal.component';

@Component({
  templateUrl: './product.component.html',
})
export class ProductComponent {
  @ViewChild(AlignImagesDirective)
  private readonly aligner?: AlignImagesDirective;

  readonly fa = {
    edit: faPencil,
    delete: faTrash,
    upload: faUpload,
    order: faSortAmountAsc,
  };

  product: Product;

  get token(): string | undefined {
    return this.authService.token;
  }

  constructor(
    readonly route: ActivatedRoute,
    private readonly modalService: NgbModal,
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly productApi: ProductApi,
    private readonly imageApi: ProductImageApi,
    private readonly notifier: NotifierService,
    private readonly utility: UtilityService,
    readonly title: Title,
  ) {
    this.product = route.snapshot.data['product'];

    title.setTitle(`Alex den Braver - ${this.product.name}`);
  }

  async edit(): Promise<void> {
    const modal = this.modalService.open(ProductModalComponent);
    const instance: ProductModalComponent = modal.componentInstance;

    instance.product = this.product;

    try {
      this.product = await modal.result;

      this.aligner?.align();
    } catch {}
  }

  async delete(): Promise<void> {
    if (!(await this.utility.delete(this.product.name))) {
      return;
    }

    await firstValueFrom(this.productApi.delete(this.product.slug));

    this.aligner?.align();

    this.notifier.notify('success', `${this.product.name} is verwijderd.`);

    this.router.navigate(['/wat-ik-maak']);
  }

  async upload(): Promise<void> {
    const modal = this.modalService.open(ImagesModalComponent);
    const instance: ImagesModalComponent = modal.componentInstance;

    instance.product = this.product;

    try {
      this.product._productImages?.push(...(await modal.result));

      this.aligner?.align();
    } catch {}
  }

  async order(): Promise<void> {
    const modal = this.modalService.open(OrderModalComponent, { size: 'xl' });
    const instance: OrderModalComponent = modal.componentInstance;

    instance.product = this.product;
    instance.images = [...(this.product._productImages ?? [])];

    try {
      this.product = await modal.result;

      this.aligner?.align();
    } catch {}
  }

  async editImage(image: ProductImage): Promise<void> {
    const modal = this.modalService.open(ImageModalComponent);
    const instance: ImageModalComponent = modal.componentInstance;

    instance.image = image;

    try {
      Object.assign(image, await modal.result);

      this.aligner?.align();
    } catch {}
  }

  async deleteImage(image: ProductImage): Promise<void> {
    if (!(await this.utility.delete('deze foto'))) {
      return;
    }

    await firstValueFrom(this.imageApi.delete(image.id));

    this.aligner?.align();

    this.notifier.notify('success', 'Foto is verwijderd.');

    this.product._productImages = this.product._productImages!.filter(
      pi => pi.id !== image.id,
    );
  }
}
