import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Directive({
  selector: '.polygon',
})
export class PolygonDirective {
  constructor(
    readonly renderer: Renderer2,
    readonly elementRef: ElementRef<HTMLImageElement>,
    readonly sanitizer: DomSanitizer,
  ) {
    renderer.setProperty(
      elementRef.nativeElement,
      'style',
      [
        Math.random() < 0.5 ? 'lt' : 'rt',
        Math.random() < 0.5 ? 'tl' : 'bl',
        Math.random() < 0.5 ? 'tr' : 'br',
      ]
        .map(x => `--polygon-${x}: ${(Math.random() * 100) / 20}%`)
        .join('; '),
    );
  }
}
