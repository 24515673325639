import { Injectable, OnDestroy } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { BehaviorSubject, timer } from 'rxjs';
import { delayWhen, distinctUntilChanged, filter, map } from 'rxjs/operators';

@Injectable()
export class LoadingService implements OnDestroy {
  private loader = new BehaviorSubject<number>(0);

  loading$ = this.loader.pipe(
    distinctUntilChanged(),
    delayWhen(x => timer(x ? 0 : 400)),
    map(Boolean),
  );

  constructor(readonly router: Router) {
    router.events
      .pipe(
        map(event => {
          switch (true) {
            case event instanceof NavigationStart:
              return true;
            case event instanceof NavigationEnd:
            case event instanceof NavigationCancel:
            case event instanceof NavigationError:
              return false;
            default:
              return null;
          }
        }),
        filter(x => x != null),
        map(go => this.loader.value + (go ? 1 : -1)),
      )
      .subscribe(this.loader);
  }

  ngOnDestroy(): void {
    this.loader.next(0);
    this.loader.complete();
  }

  load(): void {
    this.loader.next(this.loader.value + 1);
  }

  done(): void {
    this.loader.next(this.loader.value - 1);
  }
}
