import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { VideoApi } from '@app/_shared/apis';
import { Video } from '@app/_shared/entities';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { firstValueFrom } from 'rxjs';

function youtubeVimeoValidator(
  control: AbstractControl,
): ValidationErrors | null {
  const contains =
    control.value.includes('youtube') || control.value.includes('vimeo');

  return !contains ? { youtubevimeo: { value: control.value } } : null;
}

@Component({
  templateUrl: './video-modal.component.html',
})
export class VideoModalComponent implements OnInit {
  readonly fa = {
    save: faPencil,
  };

  readonly form = new FormGroup({
    id: new FormControl<number>(undefined as unknown as number, {
      nonNullable: true,
    }),
    source: new FormControl<string>('', {
      nonNullable: true,
      validators: [
        Validators.required,
        Validators.pattern(
          /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
        ),
        youtubeVimeoValidator,
      ],
    }),
    description: new FormControl<string>('', {
      nonNullable: true,
    }),
  });

  video?: Video;

  constructor(
    private readonly modal: NgbActiveModal,
    private readonly notifier: NotifierService,
    private readonly videoApi: VideoApi,
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.video) {
      this.form.patchValue(this.video);
    }
  }

  async save(): Promise<void> {
    if (this.form.invalid) {
      return;
    }

    try {
      const raw: Partial<Video> = this.form.value;

      const video = await firstValueFrom(this.videoApi.save(raw));

      this.notifier.notify('success', 'Video is opgeslagen.');

      this.modal.close(video);
    } catch {}
  }

  close(): void {
    this.modal.dismiss();
  }
}
