import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { User } from '../entities';
import { Credentials } from '../interfaces';
import { toClass, toClasses } from '../operators';

@Injectable()
export class UserApi {
  constructor(private readonly http: HttpClient) {}

  login(credentials: Partial<Credentials>): Observable<string> {
    return this.http
      .post<{ token: string }>('/api/login', credentials)
      .pipe(map(p => p.token));
  }

  profile(): Observable<string> {
    return this.http
      .get<{ token: string }>('/api/profile')
      .pipe(map(p => p.token));
  }

  findAll(): Observable<User[]> {
    return this.http.get<User[]>('/api/users').pipe(toClasses(User));
  }

  findOneById(id: number): Observable<User> {
    return this.http.get<User>(`/api/user/${id}`).pipe(toClass(User));
  }

  save(user: Partial<User>): Observable<User> {
    return this.http.post<User>('/api/user', user);
  }

  deblock(id: number): Observable<void> {
    return this.http.get<void>(`/api/user/${id}/deblock`);
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(`/api/user/${id}`);
  }
}
