<form [formGroup]="$any(form)" (submit)="save()">
  <div class="modal-header">
    <h4 class="modal-title">Categorieën bewerken</h4>
    <button type="button" class="btn btn-close" (click)="close()"></button>
  </div>
  <div
    *ngFor="let control of form.controls; let i = index"
    class="modal-body border-bottom"
  >
    <div [formGroupName]="i" class="form-group">
      <label for="name" class="required">Naam</label>
      <input
        type="text"
        id="name"
        name="name"
        formControlName="name"
        #name="formControl"
        class="form-control"
      />
      <span *ngIf="name.errors?.['required']" class="invalid-feedback">
        Dit veld is verplicht.
      </span>
      <span *ngIf="name.errors?.['maxlength']" class="invalid-feedback">
        Dit veld mag maximaal 200 karakters lang zijn.
      </span>
    </div>

    <div class="d-flex justify-content-end">
      <button type="button" class="d-flex btn btn-danger" (click)="delete(i)">
        <fa-icon [icon]="fa.delete" class="me-2"></fa-icon>
        <div>verwijderen</div>
      </button>
    </div>
  </div>

  <div class="modal-body">
    <div class="d-flex justify-content-end">
      <button type="button" class="d-flex btn btn-primary" (click)="add()">
        <fa-icon [icon]="fa.add" class="me-2"></fa-icon>
        <div>toevoegen</div>
      </button>
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-end">
    <button
      type="submit"
      class="btn btn-success"
      [disabled]="form.invalid || !form.dirty"
    >
      <fa-icon [icon]="fa.save" class="me-1"></fa-icon>
      opslaan
    </button>
  </div>
</form>
