import { Component } from '@angular/core';
import { ImageApi } from '@app/_shared/apis';
import { Image } from '@app/_shared/entities';
import { dataUrl } from '@app/_shared/utility';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { firstValueFrom } from 'rxjs';

@Component({
  templateUrl: './images-modal.component.html',
})
export class ImagesModalComponent {
  readonly fa = {
    upload: faUpload,
  };

  dragging = false;

  uris: string[] = [];
  images: File[] = [];

  constructor(
    private readonly modal: NgbActiveModal,
    private readonly notifier: NotifierService,
    private readonly imageApi: ImageApi,
  ) {}

  async setImages(files: FileList): Promise<void> {
    for (const file of Array.from(files)) {
      this.images.push(file);

      this.uris.push(await dataUrl(file));
    }
  }

  async upload(): Promise<void> {
    const images: Image[] = [];
    for (const image of this.images) {
      images.push(await firstValueFrom(this.imageApi.upload(image)));
    }

    this.notifier.notify('success', "De foto's zijn geüpload.");

    this.modal.close(images);
  }

  close(): void {
    this.modal.dismiss();
  }
}
