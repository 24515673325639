import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/_shared/services';
import { NotifierService } from 'angular-notifier';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private readonly router: Router,
    private readonly notifier: NotifierService,
    private readonly authService: AuthService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap({
        error: err => {
          if (err instanceof HttpErrorResponse) {
            switch (err.status) {
              case 401:
                this.authService.logout();

                this.router.navigate(['/login']);
                break;
              case 404:
              case 405:
                break;
              case 413:
                this.notifier.notify('error', 'Het bestand is te groot...');
                break;
              case 422:
                this.notifier.notify(
                  'error',
                  'Niet alle velden zijn correct ingevuld... ',
                );
                break;
              default:
                this.notifier.notify('error', 'Er is iets misgegaan...');
            }
          }
        },
      }),
    );
  }
}
