import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductType } from '../entities';
import { toClass, toClasses } from '../operators';

@Injectable()
export class ProductTypeApi {
  constructor(private readonly http: HttpClient) {}

  findAll(): Observable<ProductType[]> {
    return this.http
      .get<ProductType[]>('/api/product-types')
      .pipe(toClasses(ProductType));
  }

  findOneById(id: number): Observable<ProductType> {
    return this.http
      .get<ProductType>(`/api/product-type/${id}`)
      .pipe(toClass(ProductType));
  }

  save(type: Partial<ProductType>): Observable<ProductType> {
    return this.http
      .post<ProductType>('/api/product-type', type)
      .pipe(toClass(ProductType));
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(`/api/product-type/${id}`);
  }
}
