<app-admin-bar>
  <ng-template>
    <a role="button" (click)="add()">
      <fa-icon [icon]="fa.plus"></fa-icon>
      <span>toevoegen</span>
    </a>
    <a role="button" (click)="editTypes()">
      <fa-icon [icon]="fa.types"></fa-icon>
      <span>categorieën</span>
    </a>
  </ng-template>
</app-admin-bar>

<div class="container">
  <div class="d-flex justify-content-between align-items-center mb-4">
    <h3 class="mb-0">wat ik maak</h3>

    <div *ngIf="types.length" [style.width.px]="200">
      <ng-select
        [items]="types"
        [(ngModel)]="type"
        bindLabel="name"
        placeholder="filteren"
      ></ng-select>
    </div>
  </div>

  <div appAlignImages class="row g-4">
    <div
      *ngFor="let product of products"
      class="col-lg-2 col-md-3 col-sm-4 col-6"
      [class.d-none]="type && type.id !== product._productType?.id"
    >
      <a
        [routerLink]="['/wat-ik-maak', product.slug]"
        class="h-100 d-flex flex-column justify-content-end align-items-center unlink"
      >
        <img
          *ngIf="product._productImage"
          [ngSrc]="product._productImage.url"
          [alt]="product.name"
          class="w-100 h-auto polygon mb-2"
          [width]="product._productImage.width"
          [height]="product._productImage.height"
          sizes="(min-width: 992px) 16vw, (min-width: 768px) 25vw, (min-width: 576px) 33vw, 50vw"
          loading="lazy"
        />

        <div class="text-center">{{ product.name }}</div>
      </a>
    </div>
  </div>
</div>
