<form [formGroup]="form" (submit)="save()">
  <div class="modal-header">
    <h4 class="modal-title">
      {{ product ? 'Product aanpassen' : 'Product toevoegen' }}
    </h4>
    <button type="button" class="btn btn-close" (click)="close()"></button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label for="name" class="required">Naam</label>
      <input
        type="text"
        id="name"
        name="name"
        formControlName="name"
        #name="formControl"
        class="form-control"
      />
      <span *ngIf="name.errors?.['required']" class="invalid-feedback">
        Dit veld is verplicht.
      </span>
      <span *ngIf="name.errors?.['maxlength']" class="invalid-feedback">
        Dit veld mag maximaal 200 karakters lang zijn.
      </span>
      <span *ngIf="name.errors?.['unique']" class="invalid-feedback">
        Dit veld moet uniek zijn.
      </span>
    </div>

    <div class="form-group">
      <label for="_productType">Categorie</label>
      <ng-select
        [items]="types$ | async"
        formControlName="_productType"
        #_productType="formControl"
        bindLabel="name"
      ></ng-select>
    </div>

    <div class="form-group">
      <label for="date" class="required">Datum</label>
      <input
        type="date"
        id="date"
        name="date"
        formControlName="date"
        #date="formControl"
        class="form-control"
      />
      <span *ngIf="date.errors?.['required']" class="invalid-feedback">
        Dit veld is verplicht.
      </span>
    </div>

    <div class="form-group">
      <label for="description">Beschrijving</label>
      <ckeditor
        formControlName="description"
        #description="formControl"
      ></ckeditor>
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-end">
    <button
      type="submit"
      class="btn btn-success"
      [disabled]="form.invalid || !form.dirty"
    >
      <fa-icon [icon]="fa.save" class="me-1"></fa-icon>
      opslaan
    </button>
  </div>
</form>
