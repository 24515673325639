import {
  Component,
  ContentChild,
  ElementRef,
  TemplateRef,
} from '@angular/core';
import { AuthService } from '@app/_shared/services';
import { faSignOut, faUsers } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { UsersModalComponent } from '../users-modal/users-modal.component';

@Component({
  selector: 'app-admin-bar',
  templateUrl: './admin-bar.component.html',
})
export class AdminBarComponent {
  readonly fa = {
    users: faUsers,
    signout: faSignOut,
  };

  @ContentChild(TemplateRef) buttons?: TemplateRef<ElementRef>;

  get loggedin(): boolean {
    return Boolean(this.authService.token);
  }

  constructor(
    private readonly authService: AuthService,
    private readonly notifier: NotifierService,
    private readonly modalService: NgbModal,
  ) {}

  users(): void {
    this.modalService.open(UsersModalComponent);
  }

  logout(): void {
    this.authService.logout();

    this.notifier.notify('success', 'Je bent uitgelogd.');
  }
}
