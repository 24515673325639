import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ImageApi } from '@app/_shared/apis';
import { Image } from '@app/_shared/entities';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { firstValueFrom } from 'rxjs';

@Component({
  templateUrl: './image-modal.component.html',
})
export class ImageModalComponent implements OnInit {
  readonly fa = {
    save: faPencil,
  };

  readonly form = new FormGroup({
    id: new FormControl<number>(undefined as unknown as number, {
      nonNullable: true,
    }),
    description: new FormControl<string>('', {
      nonNullable: true,
    }),
  });

  image?: Image;

  constructor(
    private readonly modal: NgbActiveModal,
    private readonly notifier: NotifierService,
    private readonly imageApi: ImageApi,
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.image) {
      this.form.patchValue(this.image);
    }
  }

  async save(): Promise<void> {
    if (this.form.invalid) {
      return;
    }

    try {
      const raw: Partial<Image> = this.form.value;

      const image = await firstValueFrom(this.imageApi.save(raw));

      this.notifier.notify('success', 'Foto is opgeslagen.');

      this.modal.close(image);
    } catch {}
  }

  close(): void {
    this.modal.dismiss();
  }
}
