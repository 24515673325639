import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductImage } from '../entities';
import { toClass, toClasses } from '../operators';

@Injectable()
export class ProductImageApi {
  constructor(private readonly http: HttpClient) {}

  findAll(): Observable<ProductImage[]> {
    return this.http
      .get<ProductImage[]>('/api/product-images')
      .pipe(toClasses(ProductImage));
  }

  findOneById(id: number): Observable<ProductImage> {
    return this.http
      .get<ProductImage>(`/api/product-image/${id}`)
      .pipe(toClass(ProductImage));
  }

  save(image: Partial<ProductImage>): Observable<ProductImage> {
    return this.http
      .post<ProductImage>('/api/product-image', image)
      .pipe(toClass(ProductImage));
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(`/api/product-image/${id}`);
  }
}
