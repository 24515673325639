<form [formGroup]="form" (submit)="save()">
  <div class="modal-header">
    <h4 class="modal-title">Foto aanpassen</h4>
    <button type="button" class="btn btn-close" (click)="close()"></button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label for="description">Beschrijving</label>
      <ckeditor formControlName="description"></ckeditor>
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-end">
    <button
      type="submit"
      class="btn btn-success"
      [disabled]="form.invalid || !form.dirty"
    >
      <fa-icon [icon]="fa.save" class="me-1"></fa-icon>
      opslaan
    </button>
  </div>
</form>
