import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ProductTypeApi } from '@app/_shared/apis';
import { ProductType } from '@app/_shared/entities';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TypesResolver implements Resolve<ProductType[]> {
  constructor(private readonly typeApi: ProductTypeApi) {}

  resolve(): Observable<ProductType[]> {
    return this.typeApi.findAll();
  }
}
