import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  ImageApi,
  ProductApi,
  ProductImageApi,
  ProductTypeApi,
  UserApi,
  VideoApi,
} from './apis';
import {
  AdminBarComponent,
  DeleteModalComponent,
  UsersModalComponent,
} from './components';
import {
  AlignImagesDirective,
  CkeditorDirective,
  DateInputDirective,
  FormArrayNameExport,
  FormControlNameExport,
  FormGroupNameExport,
  PolygonDirective,
} from './directives';
import { AuthService, LoadingService, UtilityService } from './services';

@NgModule({
  imports: [
    CommonModule,
    DragDropModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgOptimizedImage,
    NgbModule,
    CKEditorModule,
    NgSelectModule,
    FontAwesomeModule,
  ],
  declarations: [
    AdminBarComponent,
    UsersModalComponent,
    DeleteModalComponent,
    DateInputDirective,
    FormControlNameExport,
    FormGroupNameExport,
    CkeditorDirective,
    FormArrayNameExport,
    PolygonDirective,
    AlignImagesDirective,
  ],
  providers: [
    UtilityService,
    ImageApi,
    VideoApi,
    UserApi,
    ProductApi,
    ProductImageApi,
    ProductTypeApi,
  ],
  exports: [
    CommonModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    NgOptimizedImage,
    NgbModule,
    CKEditorModule,
    NgSelectModule,
    FontAwesomeModule,
    AdminBarComponent,
    UsersModalComponent,
    DateInputDirective,
    FormControlNameExport,
    FormGroupNameExport,
    CkeditorDirective,
    FormArrayNameExport,
    PolygonDirective,
    AlignImagesDirective,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [AuthService, LoadingService],
    };
  }
}
