import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { UserApi } from '@app/_shared/apis';
import { AuthService } from '@app/_shared/services';
import { NotifierService } from 'angular-notifier';
import { firstValueFrom } from 'rxjs';

@Component({
  templateUrl: './login.component.html',
})
export class LoginComponent {
  readonly form = new FormGroup({
    mail: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, Validators.email],
    }),
    password: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, Validators.minLength(8)],
    }),
  });

  constructor(
    private readonly userApi: UserApi,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly notifier: NotifierService,
    readonly title: Title,
  ) {
    title.setTitle('Alex den Braver - Login');
  }

  async login(): Promise<void> {
    if (this.form.invalid) {
      return;
    }

    try {
      const token = await firstValueFrom(this.userApi.login(this.form.value));

      this.authService.login(token);

      this.notifier.notify('success', 'Je bent ingelogd.');

      this.router.navigate(['/']);
    } catch {
      this.notifier.notify('error', 'Het is niet gelukt in te loggen...');
    }
  }
}
