<form [formGroup]="form" (submit)="save()">
  <div class="modal-header">
    <h4 class="modal-title">
      {{ video ? 'Filmpje aanpassen' : 'Filmpje toevoegen' }}
    </h4>
    <button type="button" class="btn btn-close" (click)="close()"></button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label for="source" class="required">URL</label>
      <input
        type="text"
        id="source"
        name="source"
        formControlName="source"
        #source="formControl"
        class="form-control"
      />
      <span *ngIf="source.errors?.['required']" class="invalid-feedback">
        Dit veld is verplicht.
      </span>
      <span *ngIf="source.errors?.['pattern']" class="invalid-feedback">
        Dit veld moet een URL zijn.
      </span>
      <span *ngIf="source.errors?.['youtubevimeo']" class="invalid-feedback">
        Het moet een YouTube- of Vimeo-URL zijn.
      </span>
    </div>
    <div class="form-group">
      <label for="description">Beschrijving</label>
      <ckeditor formControlName="description"></ckeditor>
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-end">
    <button
      type="submit"
      class="btn btn-success"
      [disabled]="form.invalid || !form.dirty"
    >
      <fa-icon [icon]="fa.save" class="me-1"></fa-icon>
      opslaan
    </button>
  </div>
</form>
