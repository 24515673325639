<div class="modal-header">
  <h4 class="modal-title">Volgorde veranderen</h4>
  <button type="button" class="btn btn-close" (click)="close()"></button>
</div>
<div class="modal-body">
  <div
    cdkDropList
    class="d-flex justify-content-center align-items-center mx-n2"
    (cdkDropListDropped)="drop($any($event))"
  >
    <div *ngFor="let image of images" cdkDrag class="mx-2">
      <img
        [ngSrc]="image.url"
        class="mw-100 h-auto"
        [width]="image.width"
        [height]="image.height"
        sizes="10vw"
      />
    </div>
  </div>
</div>
<div class="modal-footer d-flex justify-content-end">
  <button type="button" class="btn btn-success" (click)="save()">
    <fa-icon [icon]="fa.save" class="me-1"></fa-icon>
    opslaan
  </button>
</div>
