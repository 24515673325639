import { Directive, Self } from '@angular/core';
import { FormArrayName, FormControlName, FormGroupName } from '@angular/forms';

@Directive({ selector: '[formControlName]', exportAs: 'formControl' })
export class FormControlNameExport extends FormControlName {
  constructor(@Self() formControlName: FormControlName) {
    super(<any>null, <any>null, <any>null, <any>null, <any>null);

    return formControlName;
  }
}

@Directive({ selector: '[formGroupName]', exportAs: 'formGroup' })
export class FormGroupNameExport extends FormGroupName {
  constructor(@Self() formGroupName: FormGroupName) {
    super(<any>null, <any>null, <any>null);

    return formGroupName;
  }
}

@Directive({ selector: '[formArrayName]', exportAs: 'formArray' })
export class FormArrayNameExport extends FormArrayName {
  constructor(@Self() formArrayName: FormArrayName) {
    super(<any>null, <any>null, <any>null);

    return formArrayName;
  }
}
