import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Image } from '../entities';
import { toClass, toClasses } from '../operators';

@Injectable()
export class ImageApi {
  constructor(private readonly http: HttpClient) {}

  findAll(): Observable<Image[]> {
    return this.http.get<Image[]>('/api/images').pipe(toClasses(Image));
  }

  findOneById(id: number): Observable<Image> {
    return this.http.get<Image>(`/api/image/${id}`).pipe(toClass(Image));
  }

  save(image: Partial<Image>): Observable<Image> {
    return this.http.post<Image>('/api/image', image).pipe(toClass(Image));
  }

  upload(file: File): Observable<Image> {
    const formData = new FormData();
    formData.append('file', file);

    return this.http
      .post<Image>(`/api/images/upload`, formData)
      .pipe(toClass(Image));
  }

  order(images: Partial<Image>[]): Observable<Image[]> {
    return this.http
      .post<Image[]>('/api/images/order', images)
      .pipe(toClasses(Image));
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(`/api/image/${id}`);
  }
}
