<app-admin-bar>
  <ng-template>
    <a role="button" (click)="edit()">
      <fa-icon [icon]="fa.edit"></fa-icon>
      <span>bewerken</span>
    </a>
    <a role="button" (click)="delete()">
      <fa-icon [icon]="fa.delete"></fa-icon>
      <span>verwijderen</span>
    </a>
    <a role="button" (click)="upload()">
      <fa-icon [icon]="fa.upload"></fa-icon>
      <span>uploaden</span>
    </a>
    <a
      *ngIf="product._productImages && product._productImages.length > 1"
      role="button"
      (click)="order()"
    >
      <fa-icon [icon]="fa.order"></fa-icon>
      <span>volgorde</span>
    </a>
  </ng-template>
</app-admin-bar>

<div class="container">
  <h3 class="d-flex justify-content-between mb-4">
    <span>
      <span class="d-none d-sm-inline">
        <a [routerLink]="['/wat-ik-maak']" class="unlink">wat ik maak</a>
        <span class="text-muted">&nbsp;&raquo;</span>
      </span>

      {{ product.name }}
    </span>

    <span class="d-none d-md-inline">
      {{ product.date | date : 'MMMM YYYY' }}
    </span>
    <span class="d-inline d-md-none">
      {{ product.date | date : 'MMM YYYY' }}
    </span>
  </h3>

  <div class="mb-4" [innerHTML]="product.description"></div>

  <div appAlignImages class="row g-4">
    <div *ngFor="let image of product._productImages" class="col-lg-6 col-12">
      <div
        class="h-100 d-flex flex-column justify-content-end align-items-center"
      >
        <div class="w-100 position-relative mb-2">
          <img
            [ngSrc]="image.url"
            [alt]="product.name + ' ' + image.name"
            class="w-100 h-auto"
            [width]="image.width"
            [height]="image.height"
            sizes="(min-width: 992px) 50vw, 100vw"
            loading="lazy"
          />

          <div *ngIf="token" class="position-absolute bottom-0 end-0">
            <button
              type="button"
              class="btn btn-success me-3 mb-3"
              (click)="editImage(image)"
            >
              <fa-icon [icon]="fa.edit" class="me-1"></fa-icon>
              bewerken
            </button>

            <button
              type="button"
              class="btn btn-danger me-3 mb-3"
              (click)="deleteImage(image)"
            >
              <fa-icon [icon]="fa.delete" class="me-1"></fa-icon>
              verwijderen
            </button>
          </div>
        </div>

        <div class="text-center" [innerHTML]="image.description"></div>
      </div>
    </div>
  </div>
</div>
