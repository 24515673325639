import { map, MonoTypeOperatorFunction } from 'rxjs';

export function toClass<T>(
  Class: new (instance: T) => T,
): MonoTypeOperatorFunction<T> {
  return map(x => new Class(x));
}

export function toClasses<T>(
  Class: new (instance: T) => T,
): MonoTypeOperatorFunction<T[]> {
  return map((x: T[]) => x.map(x => new Class(x)));
}
