import { trim } from '../utility';

export class Image {
  id: number;
  description?: string;
  width: number;
  height: number;
  order?: number;

  get url(): string {
    return `/api/image/${this.id}/download`;
  }

  get name(): string {
    return this.description?.replace(/(<([^>]+)>)/gi, '') ?? '';
  }

  constructor(image: Image) {
    this.id = image.id;
    this.description = trim(image.description || '');
    this.width = image.width;
    this.height = image.height;
    this.order = image.order;
  }
}
