import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { filter, noop } from 'rxjs';
import { UserApi } from './_shared/apis';
import { AuthService } from './_shared/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  readonly fa = {
    bars: faBars,
    times: faTimes,
  };

  navbarOpen = false;

  constructor(
    readonly modalService: NgbModal,
    readonly router: Router,
    private readonly userApi: UserApi,
    private readonly authService: AuthService,
  ) {
    router.events
      .pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
      .subscribe(() => modalService.dismissAll());
  }

  ngOnInit(): void {
    if (this.authService.token) {
      this.userApi.profile().subscribe(noop);
    }
  }
}
