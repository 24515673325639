import { trim } from '../utility';
import { ProductImage } from './product-image';
import { ProductType } from './product-type';

export class Product {
  id: number;
  slug: string;
  name: string;
  description?: string;
  date?: Date;

  _productType?: ProductType;
  _productImage?: ProductImage;
  _productImages?: ProductImage[];

  constructor(product: Product) {
    this.id = product.id;
    this.slug = product.slug;
    this.name = product.name;
    this.description = trim(product.description || '');
    this.date = product.date ? new Date(product.date) : undefined;

    this._productType = product._productType
      ? new ProductType(product._productType)
      : undefined;
    this._productImage = product._productImage
      ? new ProductImage(product._productImage)
      : undefined;
    this._productImages = product._productImages?.map(i => new ProductImage(i));
  }
}
